
import { Options, Vue } from 'vue-class-component'
import LiveMatchScore from '@/components/Frontend/LiveMatchScheduleList/LiveMatchScore/index.vue'

@Options({
  components: {
    LiveMatchScore
  }
})

export default class LiveMatchScoreView extends Vue {}
