import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class LiveMatch extends APIBaseFunctions {
  static LiveMatchDataService = class {
    private static liveMatchDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getLiveMatches () {
      return http.get('/liveMatch')
    }

    static async getLiveMatchForMatchPage (id: number | null) {
      return http.get(`/checkLiveMatch/${id}`)
    }

    static getSelectedLiveMatch (id: string) {
      return http.get(`/kamp-saet-udkasts/${id}`)
    }

    static async getLiveMatchesOfMainMatch (id: string) {
      return http.get(`/kamp-saet-udkasts?matchId=${id}&status=true`)
    }
  }
}

export default new LiveMatch()
