import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class TodayMatch extends APIBaseFunctions {
  static TodayMatchDataService = class {
    private static todayMatchDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getTodayMatches () {
      return http.get('/todaysMatch')
    }

    static getSelectedTodayMatch (id: string) {
      return http.get(`/kamp-saet-udkasts/${id}`)
    }
  }
}

export default new TodayMatch()
